/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-17",
    versionChannel: "nightly",
    buildDate: "2024-07-17T00:05:50.272Z",
    commitHash: "bb49deecf8edc6d29c1559949cf24725b871188a",
};
